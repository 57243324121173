const api = "canteen.json";

let result = '';
let lunchbook = '';
let db_profile = '';
let db_usrprofile = '';
let db_saveusers = '';
let ec_acti_log = '';
let nativeCamCheckString = '';
let db_ec_welcome_email = '';
let db_checkin = '';
let db_csv_personal_graph = '';
let db_chef_booked_orders = '';
let db_csv_chef_booked_orders = '';
let db_chef_live_view = '';
let db_depcanteen = '';
let db_usrcanteen = '';
let db_mchr_welcome_email = '';
let db_cancldays = '';
let ec_acti_get = '';
let db_open_canteen = '';
let db_book_close = '';
let db_flexi_dage = '';
let qrCodeText = '';
let appwriteEndpoint = '';
let appwriteProjectId = '';
let appwriteResetPassConfPage = '';
export default (async () => {
  const response = await fetch(api);
  const resultW = await response.json();
  result = resultW.backendurl;
  lunchbook = resultW.backendurl+"lunchbook.php";
  db_profile = resultW.backendurl+"profile.php";
  db_usrprofile = resultW.backendurl+"userprofile.php";
  db_saveusers = resultW.backendurl+"saveusers.php";
  ec_acti_log = resultW.backendurl+"ec_activity_logger.php";
  nativeCamCheckString = resultW.camstring;
  db_ec_welcome_email = resultW.backendurl+"ec_welcome_email.php";
  db_checkin = resultW.backendurl+"checkin.php";
  db_csv_personal_graph = resultW.backendurl+"graphs.php?uid=";
  db_chef_booked_orders = resultW.backendurl+"chef_booked_orders.php";
  db_csv_chef_booked_orders = resultW.backendurl+"chef_booked_orders.php?getCanteenScore=true";
  db_chef_live_view = resultW.backendurl+"chef_live_view.php";
  db_depcanteen = resultW.backendurl+"dep_canteen.php";
  db_usrcanteen = resultW.backendurl+"usrs_canteen.php";
  db_mchr_welcome_email = resultW.backendurl+"mchr_welcome_email.php";
  db_cancldays = resultW.backendurl+"canteen_closed_days.php";
  ec_acti_get = resultW.backendurl+"ec_activity_getter.php";
  db_open_canteen = resultW.backendurl+"canteen_opcl_h.php";
  db_book_close = resultW.backendurl+"book_close_h.php";
  db_flexi_dage = resultW.backendurl+"flexi_dage.php";
  qrCodeText = resultW.canteenurl+nativeCamCheckString;
  appwriteEndpoint = resultW.authendpoint;
  appwriteProjectId = resultW.authprojid;
  appwriteResetPassConfPage = resultW.canteenurl+"confirmpassreset";
})();

export {result, lunchbook, db_profile, db_usrprofile, db_saveusers, ec_acti_log, nativeCamCheckString,
  db_ec_welcome_email, db_checkin, db_csv_personal_graph, db_chef_booked_orders, db_csv_chef_booked_orders,
  db_chef_live_view, db_depcanteen, db_usrcanteen, db_mchr_welcome_email, db_cancldays, ec_acti_get,
  db_open_canteen, db_book_close, db_flexi_dage, qrCodeText, appwriteEndpoint, appwriteProjectId, appwriteResetPassConfPage
};

export const versionNr = "version 1.7.4 beta dev";